import * as React from 'react';
import { useRef, useState } from 'react';
import MainLayout from '../layouts/main-layout/main-layout';
import { graphql } from 'gatsby';
import { Link } from 'gatsby-plugin-react-intl';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useForceRender } from '../hooks/force-render';
import Gallery from '../components/gallery/gallery';
import ParsedContent from '../components/parsed-content/parsed-content';

import * as styles from '../styles/pages/project.module.scss';

const ProjectPageTemplate = ({ data }) => {
	const intl = useIntl();
	const forceRender = useForceRender();

	const project = data?.api?.projects[0];

	const [galleryState, setGalleryState] = useState({
		opened: false,
	});
	const prevBtnRef = useRef(null);
	const nextBtnRef = useRef(null);

	return (
		<MainLayout
			seo={{
				title: project?.title,
				description: data?.api?.seo?.siteDescription,
				meta: [
					{
						name: 'og:image',
						content:
							project?.gallery[0]?.file?.childImageSharp?.gatsbyImageData?.images?.fallback?.src ??
							'',
					},
				],
			}}
		>
			<section className={styles.projectInfoSection}>
				<Link to="/projects" className={styles.goBackLink}>
					<i className="far fa-chevron-left" />
					{intl.formatMessage({
						id: 'project-page-go-back-link',
						defaultMessage: 'Back to projects',
					})}
				</Link>

				<h1>{project?.title}</h1>
			</section>

			{project?.gallery?.length > 0 && (
				<React.Fragment>
					<section className={styles.gallerySection}>
						<Swiper
							slidesPerView={1}
							navigation={{
								prevEl: prevBtnRef.current,
								nextEl: nextBtnRef.current,
							}}
							onInit={() => {
								// Needed to correctly link navigation refs
								forceRender();
							}}
							className={styles.gallery}
						>
							{project?.gallery?.map((image, i) => (
								<SwiperSlide
									key={`projectImage${i}`}
									className={styles.slide}
									onClick={() =>
										setGalleryState({
											opened: true,
											slide: i,
										})
									}
								>
									<GatsbyImage
										title={image?.meta?.title}
										alt={image?.meta?.alt ?? ''}
										image={getImage(image?.file)}
										objectFit="contain"
										objectPosition="center"
										className={styles.image}
									/>
								</SwiperSlide>
							))}
						</Swiper>

						<button ref={prevBtnRef} className={styles.prevBtn}>
							<i className="fal fa-chevron-left" />
						</button>
						<button ref={nextBtnRef} className={styles.nextBtn}>
							<i className="fal fa-chevron-right" />
						</button>
					</section>

					<Gallery state={galleryState} images={project?.gallery ?? []} />
				</React.Fragment>
			)}

			<section className={styles.projectInfoSection}>
				<ParsedContent className={styles.description} html={project?.description} />
			</section>
		</MainLayout>
	);
};

export const query = graphql`
	query ($slugFilter: API_JsonType!, $language: String!) {
		api {
			seo: seoSingleton(lang: $language) {
				siteDescription
			}

			projects: projectsCollection(filter: $slugFilter, lang: $language) {
				title
				description
				gallery {
					path
					meta
					file {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			}
		}
	}
`;

export default ProjectPageTemplate;
