// extracted by mini-css-extract-plugin
export var closeBtn = "gallery-module--close-btn--5ec96";
export var enter = "gallery-module--enter--0d72a";
export var enterActive = "gallery-module--enter-active--8e8e7";
export var exit = "gallery-module--exit--2c973";
export var exitActive = "gallery-module--exit-active--e3659";
export var gallery = "gallery-module--gallery--81bd1";
export var image = "gallery-module--image--be0c4";
export var navigation = "gallery-module--navigation--bbc29";
export var nextBtn = "gallery-module--next-btn--d1a08";
export var pagination = "gallery-module--pagination--b1f80";
export var prevBtn = "gallery-module--prev-btn--bbc83";
export var slide = "gallery-module--slide--23872";
export var slider = "gallery-module--slider--39050";
export var topBar = "gallery-module--top-bar--a2fdc";